// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes _5lbSD2hpm6yogvdQ7RcT{from{opacity:0}to{opacity:1}}.EOb9pcoDqlfxGQD142er{width:100%;animation:_5lbSD2hpm6yogvdQ7RcT 2s ease-in forwards;display:flex !important;flex-direction:column;align-items:center;margin-top:150px}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/BrandUpdates.module.scss"],"names":[],"mappings":"AAGA,iCACE,KACE,SAAA,CAEF,GACE,SAAA,CAAA,CAIJ,sBACE,UAAA,CACA,mDAAA,CACA,uBAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":["@import 'variables';\n\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.brandUpdates {\n  width: 100%;\n  animation: fadeIn 2s ease-in forwards;\n  display: flex !important;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandUpdates": `EOb9pcoDqlfxGQD142er`,
	"fadeIn": `_5lbSD2hpm6yogvdQ7RcT`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from './styles/TopicReport.module.scss';
import { useFeed } from "../contexts/FeedContext";
import HeadlineSection from './topic_report/HeadlineSection';
import InTheHeadlines from './topic_report/InTheHeadlines';
import OnTheWeb from './topic_report/OnTheWeb';
import Strategy from './topic_report/Strategy';
import Recommendations from './topic_report/Recommendations';
import WaitingScreen from "./onboarding/WaitingScreen";

const TopicReport = ({ topicFeedCollapsed }) => {
  const { topic, loading } = useFeed();
  // debugger
  if (loading || !topic) return <WaitingScreen text={"Generating your unique strategies"}/>;

  return (
    <div className={topicFeedCollapsed ? `${styles.topicReport} ${styles.topicFeedCollapsed}` : styles.topicReport}>
      <div className={styles.topicData}>
        <HeadlineSection topicHeadline={topic.name} subHeadline={topic.description} />
        <InTheHeadlines articles={topic.newsArticles} />
        <OnTheWeb posts={topic.socialMediaPosts} />
      </div>
      <div className={styles.strategySection}>
        <Strategy strategy={topic.strategy} />
        <Recommendations recommendations={topic.recommendations} />
      </div>
    </div>
  );
};

export default TopicReport;
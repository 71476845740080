import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from "axios";
import Spinner from "../components/shared/Spinner";

const CompanyDetailsContext = createContext();

export const useCompanyDetails = () => useContext(CompanyDetailsContext);

export const CompanyDetailsProvider = ({ children }) => {
  const [companyDetails, setCompanyDetails] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get('/company_details');
      setCompanyDetails(response.data);
    } catch (error) {
      console.error('Failed to fetch company details:', error);
    } finally {
      setLoading(false); // Set loading to false after fetch completes
    }
  };

  const updateCompanyDetails = async (newDetails) => {
    try {
      const response = await axios.patch('/company_details', newDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setCompanyDetails(response.data);
    } catch (error) {
      console.error('Error updating company details:', error);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const value = {
    companyDetails,
    updateCompanyDetails,
    fetchCompanyDetails,
    setCompanyDetails
  };

  if (loading) {
    return <Spinner  width="50" height="50" marginTop="15%"/>;
  }

  return <CompanyDetailsContext.Provider value={value}>{children}</CompanyDetailsContext.Provider>;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EwwIJbUKCDr1A1YilUm8{display:flex;flex-direction:column;align-items:start;margin-bottom:30px}.EwwIJbUKCDr1A1YilUm8 .cwttjwuse9RoObQqzhIi{font-size:18px;font-weight:400;color:#170e25;margin-bottom:15px}.EwwIJbUKCDr1A1YilUm8 .IdUiF6ZBLvFP7HpawEqQ{font-size:30px;font-weight:800;color:#170e25}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/topic_report/HeadlineSection.module.scss","webpack://./app/javascript/components/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CAEA,4CACE,cAAA,CACA,eAAA,CACA,aCTQ,CDUR,kBAAA,CAGF,4CACE,cAAA,CACA,eAAA,CACA,aChBQ","sourcesContent":["@import '../variables';\n\n.headlineComponent {\n  display: flex;\n  flex-direction: column;\n  align-items: start; // Adjust the padding as needed for spacing around the component\n  margin-bottom: 30px;\n\n  .topicHeadline {\n    font-size: 18px;\n    font-weight: 400;\n    color: $font-bold; // Adjust the color to fit your design palette\n    margin-bottom: 15px; // Space between the main headline and subheadline\n  }\n\n  .subHeadline {\n    font-size: 30px;\n    font-weight: 800;\n    color: $font-bold; // Ensure the subheadline stands out, adjust color as needed\n  }\n}\n","$white-background: #ffffff;\n$black-background: #000000;\n$font-bold: #170E25;\n$font-blue: #1462D4;\n$font-grey: #D0D0D0;\n$font-white: #ffffff;\n$font-red: #A64206;\n$font-orange: #C86026;\n$line-grey: #D9D9D9;\n$background-red: #FFF2EB;\n$background-blue: #EBF3FF;\n$background-yellow: #FEF9DA;\n$red: #E84260;\n$green: #0E574A;\n$light-green: #E3F9F5;\n$blue: #326A92;\n$burnside-blue: #1462D4;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headlineComponent": `EwwIJbUKCDr1A1YilUm8`,
	"topicHeadline": `cwttjwuse9RoObQqzhIi`,
	"subHeadline": `IdUiF6ZBLvFP7HpawEqQ`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Paper, Typography, Box } from '@mui/material';

const UpdateItem = ({ item, index, totalItems }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const year = date.getFullYear();
    return { formattedDate, year };
  };

  const { formattedDate, year } = formatDate(item.created_at);

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0.1, paddingRight: '8px' }}>
        <Typography variant="subtitle1" color="#000000">
          {formattedDate}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {year}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ color: "#000000", backgroundColor: "#000000" }} />
        {index < totalItems - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ flex: 0.9, display: 'flex', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '16px', display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ marginRight: '16px', flexShrink: 0 }}>
            <img src="https://rally-public-company-images.s3.amazonaws.com/logos/logo_mark.png" alt="Logo" width={25} height={25} />
          </Box>
          <Typography sx={{ textAlign: 'left' }}>{item.description}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default UpdateItem;

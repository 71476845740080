// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pwp46qWLHneclkrv9hIZ{display:flex;align-items:center;justify-content:center;height:80vh;text-align:center}.VXkAklbCnpXdPjiuqWio{border:4px solid rgba(0,0,0,.1);border-top:4px solid #1462d4;border-radius:50%;width:36px;height:36px;animation:ZYV2LLnJbj_lgIy4IvvR 2s linear infinite;margin-right:16px}@keyframes ZYV2LLnJbj_lgIy4IvvR{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.m8LpB0Ou9trrvmzPp483{color:#1462d4;font-family:"Inter",sans-serif;font-size:28px;font-style:normal;font-weight:500;line-height:150%}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/onboarding/WaitingScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBACE,+BAAA,CACA,4BAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,iDAAA,CACA,iBAAA,CAGF,gCACE,GAAA,sBAAA,CACA,KAAA,wBAAA,CAAA,CAGF,sBACE,aAAA,CACA,8BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 80vh; // Full screen height\n  text-align: center;\n}\n\n.spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1); // Light grey border\n  border-top: 4px solid #1462D4; // Blue border for the spinner part\n  border-radius: 50%;\n  width: 36px;\n  height: 36px;\n  animation: spin 2s linear infinite;\n  margin-right: 16px; // Space between spinner and text\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.message {\n  color: #1462D4;\n  font-family: 'Inter', sans-serif;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 150%; // 42px line height\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pwp46qWLHneclkrv9hIZ`,
	"spinner": `VXkAklbCnpXdPjiuqWio`,
	"spin": `ZYV2LLnJbj_lgIy4IvvR`,
	"message": `m8LpB0Ou9trrvmzPp483`
};
export default ___CSS_LOADER_EXPORT___;

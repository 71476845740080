// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes EomKgmMXQ1LWWe4ZWuGA{from{opacity:0}to{opacity:1}}.i0LBK8Ls0yaWv4FMwARq{align-items:left;animation:EomKgmMXQ1LWWe4ZWuGA 2s ease-in forwards}.i0LBK8Ls0yaWv4FMwARq h2{margin-top:0;color:#000;font-size:20px}.i0LBK8Ls0yaWv4FMwARq .dcKub14iuQhUH_Fj3frw{width:400px}.cYPtGNwbe045uUu8tAKA{display:flex;align-items:center}.dcKub14iuQhUH_Fj3frw{margin-right:20px}.dcKub14iuQhUH_Fj3frw:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/brand_center/ConnectAccounts.module.scss"],"names":[],"mappings":"AAAA,gCACE,KACE,SAAA,CAEF,GACE,SAAA,CAAA,CAIJ,sBAEE,gBAAA,CACA,kDAAA,CAEA,yBACE,YAAA,CACA,UAAA,CACA,cAAA,CAGF,4CACE,WAAA,CAKJ,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAGF,iCACE,cAAA","sourcesContent":["@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.connectAccounts {\n  // padding: 20px;\n  align-items: left;\n  animation: fadeIn 2s ease-in forwards;\n\n  h2 {\n    margin-top: 0;\n    color: black;\n    font-size: 20px;\n  }\n\n  .loginContainer {\n    width: 400px;\n  }\n}\n\n\n.container {\n  display: flex;     \n  align-items: center;\n}\n\n.loginContainer {\n  margin-right: 20px;\n}\n\n.loginContainer:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectAccounts": `i0LBK8Ls0yaWv4FMwARq`,
	"fadeIn": `EomKgmMXQ1LWWe4ZWuGA`,
	"loginContainer": `dcKub14iuQhUH_Fj3frw`,
	"container": `cYPtGNwbe045uUu8tAKA`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QvC3mLBVQlPXxDskmTxY{animation-name:eEr3uHpyqk6zfR0yyMwY;animation-duration:500ms;animation-iteration-count:infinite;animation-timing-function:linear}.QvC3mLBVQlPXxDskmTxY path{stroke:#000}@keyframes eEr3uHpyqk6zfR0yyMwY{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.F4CcjsGY7nR77ZD70qpy{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/Spinner.module.scss"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACA,wBAAA,CACA,kCAAA,CACA,gCAAA,CACA,2BACE,WAAA,CAIJ,gCACE,KACE,sBAAA,CAEF,GACE,wBAAA,CAAA,CAIJ,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".spinner {\n  animation-name: spin;\n  animation-duration: 500ms;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n  path {\n    stroke: #000000;\n  }\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinnerContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `QvC3mLBVQlPXxDskmTxY`,
	"spin": `eEr3uHpyqk6zfR0yyMwY`,
	"spinnerContainer": `F4CcjsGY7nR77ZD70qpy`
};
export default ___CSS_LOADER_EXPORT___;

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const manageResponse = (message) => {
  let displayMessage;

  if (Array.isArray(message)) {
    displayMessage = message.join(', ');
  } else if (typeof message === 'object' && message !== null) {
    displayMessage = Object.entries(message).map(([key, value]) => {
      return `${key.charAt(0).toUpperCase() + key.slice(1)} ${value.join(', ')}`;
    }).join(', ');
  } else {
    displayMessage = message;
  }

  return displayMessage
}
export const handleErrorResponse = (errorMessage) => {
  const message = errorMessage ?? 'Something went wrong.';
  toast.error(manageResponse(message));
};

export const handleSuccessResponse = (successMessage) => {
  toast.success(manageResponse(successMessage));
};




import React, {useRef} from "react";
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import styles from "../styles/Uploader.module.scss";

const Uploader = ({ setLogoImageUrl, setSplashImageUrl, fileType, websiteDetailId, loading, setLoading }) => {
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
 
 const handleFileChange = async (event, file_type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append(file_type, file);

      try {
        setLoading(true);
        const response = await axios.post(`/website_details/${websiteDetailId}/upload_image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          setLogoImageUrl(response.data.logo_url)
          setSplashImageUrl(response.data.secondary_image_url)
        } else {
          alert('Something went wrong with the upload.');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={`${styles.uploadIcon} ${loading ? styles.disabled : ''}`} onClick={handleIconClick} disabled={loading}>
      <FontAwesomeIcon icon={faUpload} />
        <input
          disabled={loading}
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={(event) => handleFileChange(event, fileType)}
        />
    </div> 
  )
}

export default Uploader;
import React, { useState } from 'react';
import SocialLogin from "../SocialLogin";
import styles from '../styles/onboarding/SocialLoginStep.module.scss'; // Assuming you have or will create this CSS module

const SocialLoginStep = ({ onSubmit, onSkip }) => {
    const [links, setLinks] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(links);
    };

    return (
        <form onSubmit={handleSubmit} className={styles.socialLoginStep}>
        <SocialLogin title={'Connect Your Social Accounts'} type={'personal'}/>
            <div className={styles.buttonContainer}>
                <button type="button" onClick={onSkip} className={styles.skipButton}>Skip</button>
                <button type="submit" className={styles.continueButton}>
                    Continue <span className={styles.rightArrow}>&rarr;</span>
                </button>
            </div>
        </form>
    );
};

export default SocialLoginStep;

import React, { useState, useEffect } from 'react';
import styles from '../styles/brand_center/CompanyBrand.module.scss'; // Use the same SCSS file

const AboutMe = ({ userDetails, updateUserDetails }) => {
  const [editableData, setEditableData] = useState({});
  const [editField, setEditField] = useState(null);

  useEffect(() => {
    setEditableData(userDetails);
  }, [userDetails]);

  const handleTextChange = (field) => (event) => {
    setEditableData({ ...editableData, [field]: event.target.value });
  };

  const handleBlur = (field) => {
    console.log(`Data saved for ${field}:`, editableData[field]);
    updateUserDetails(editableData);
    setEditField(null);  // Exit edit mode on blur
  };

  const handleClick = (field) => {
    setEditField(field);
  };

  const renderField = (field, value) => {
    return editField === field ? (
      <textarea
        value={value}
        onChange={handleTextChange(field)}
        onBlur={() => handleBlur(field)}
        className={styles.editableText}
        autoFocus
      />
    ) : (
      <p onClick={() => handleClick(field)} className={styles.editableParagraph}>
        {value}
      </p>
    );
  };

  return (
    <div className={styles.companyBrand}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h1 className={styles.summaryTitle}>About Me</h1>
        </div>
        <div className={styles.sectionBody}>
          <div className={styles.attributes}>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Name</h2>
              {renderField('name', editableData?.name || '')}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Title</h2>
              {renderField('title', editableData?.title || '')}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Education</h2>
              {renderField('education', editableData?.education || '')}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Work History</h2>
              {renderField('work_history', editableData?.work_history || '')}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Interests</h2>
              {renderField('interests', editableData?.interests || '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;

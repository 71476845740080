// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yMGSBd2BVb8KogYgjBtw{display:flex;flex-direction:column;align-items:center;justify-content:center}.elJb8cgTwzDPZWRDueLY{transition:opacity .3s ease-in-out;opacity:1}.XjAAj1FXJ53KFI2cJXzu{opacity:1}.J_JBb3X4t4ueqyq6o3qr{opacity:0}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/Onboarding.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,kCAAA,CACA,SAAA,CAGF,sBACE,SAAA,CAGF,sBACE,SAAA","sourcesContent":[".onboardingContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.stepContent {\n  transition: opacity 0.3s ease-in-out;\n  opacity: 1; // Default to fully visible\n}\n\n.fadeIn {\n  opacity: 1;\n}\n\n.fadeOut {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboardingContainer": `yMGSBd2BVb8KogYgjBtw`,
	"stepContent": `elJb8cgTwzDPZWRDueLY`,
	"fadeIn": `XjAAj1FXJ53KFI2cJXzu`,
	"fadeOut": `J_JBb3X4t4ueqyq6o3qr`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AuthRoute from './AuthRoute';
import Spinner from "./shared/Spinner";

const SubscriptionRoute = () => {
  const { currentUser } = useAuth();
  const [isCheckingStatus, setIsCheckingStatus] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState();


  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await fetch('/subscription_status');
        const data = await response.json();
        setHasSubscription(data.active);
        setSubscriptionStatus(data)
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        setIsCheckingStatus(false);
      }
    };

    if (currentUser) {
      checkSubscriptionStatus();
    } else {
      setIsCheckingStatus(false);
    }
  }, [currentUser]);

  if (isCheckingStatus) {
    return <Spinner  width="50" height="50" marginTop="15%"/>;
  }

  if (!window.location.hostname.includes('staging') && !hasSubscription) {
    return <Navigate to="/plans" replace state={{ data: subscriptionStatus }}/>;
  }

  return <Outlet />;
};

export default SubscriptionRoute;

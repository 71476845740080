// app/javascript/components/App.jsx

import styles from './styles/App.module.scss';
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AuthProvider } from '../contexts/AuthContext';
import { LinkedSocialsProvider } from '../contexts/LinkedSocialsContext';
import { NewsProvider } from '../contexts/NewsContext';
import { CompanyDetailsProvider } from "../contexts/CompanyDetailsContext";
import { UserDetailsProvider } from "../contexts/UserDetailsContext";
import { FeedProvider } from "../contexts/FeedContext";
import AuthRoute from './AuthRoute';
import PrivateRoute from './PrivateRoute';
import SubscriptionRoute from './SubscriptionRoute';
import Navbar from "./Navbar";
import Today from "./Today";
import Signup from "./Signup";
import Login from "./Login";
import PasswordUpdate from './PasswordUpdate';
import Onboarding from "./Onboarding";
import BrandCenter from "./BrandCenter";
import PromoLandingPage from "./PromoLandingPage";
import InjectedCheckoutForm from "./payments/InjectedCheckoutForm";
import Settings from "./Settings";
import { QueryClient, QueryClientProvider } from 'react-query';
import Footer from "./Footer";
import { ToastContainer } from 'react-toastify';
import BrandUpdates from './brand_updates';
const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <LinkedSocialsProvider>
            <Navbar />
            <ToastContainer />
            <div className={styles.appContainer}>
              <Routes>
                <Route exact path="/" element={<PrivateRoute/>}>
                  <Route path="plans" element={<CompanyDetailsProvider><InjectedCheckoutForm /></CompanyDetailsProvider>} />
                  <Route path="onboarding" element={<CompanyDetailsProvider><Onboarding /></CompanyDetailsProvider>} />

                  <Route element={<SubscriptionRoute />}>
                    <Route path="today" element={<CompanyDetailsProvider><UserDetailsProvider><FeedProvider><Today /></FeedProvider></UserDetailsProvider></CompanyDetailsProvider>} />
                    <Route path="brand-center/:section?" element={<CompanyDetailsProvider><UserDetailsProvider><BrandCenter /></UserDetailsProvider></CompanyDetailsProvider>} />
                    <Route path="brand-updates" element={<CompanyDetailsProvider><UserDetailsProvider><BrandUpdates /></UserDetailsProvider></CompanyDetailsProvider>} />
                    <Route path="settings" element={<Settings />} />
                    <Route index element={<CompanyDetailsProvider><UserDetailsProvider><FeedProvider><Today /></FeedProvider></UserDetailsProvider></CompanyDetailsProvider>} />
                  </Route>
                </Route>

                <Route exact path="/auth" element={<AuthRoute />}>
                  <Route path="signup" element={<Signup />} />
                  <Route path="login" element={<Login />} />
                  <Route path="users/password/edit" element={<PasswordUpdate />} />
                </Route>
                <Route exact path="/register" element={<PromoLandingPage />} />
              </Routes>
            </div>
          </LinkedSocialsProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
import React, { useState, useEffect} from 'react';
import TopicFeed from './TopicFeed';
import TopicReport from './TopicReport';
import styles from './styles/Today.module.scss'; // Ensure this path is correct
import { useFeed } from '../contexts/FeedContext';
import { useCompanyDetails } from '../contexts/CompanyDetailsContext';

const Today = () => {
  const [topicFeedCollapsed, setTopicFeedCollapsed] = useState(false);
  const [onboardingVisible, setOnboardingVisible] = useState(true);
  const [onboardingHeight, setOnboardingHeight] = useState('600px');
  const [headline, setHeadline] = useState('');
  const { companyDetails } = useCompanyDetails();
  const { setFeedType, feedType } = useFeed();

  useEffect(() => {
    setOnboardingVisible(new URLSearchParams(window.location.search).get('onboarding') === 'true');

    const fullHeadline = `A strategic curation of the only news ${companyDetails?.companyName || 'your company'} needs to hear about today.`

    let charIndex = -1;
    const totalDuration = 5000;
    const intervalTime = totalDuration / fullHeadline.length;

    const typeInterval = setInterval(() => {
      charIndex++;
      if (charIndex >= fullHeadline.length) {
        clearInterval(typeInterval);
        return;
      }
      setHeadline(prev => prev + fullHeadline[charIndex]);
    }, intervalTime);

    return () => clearInterval(typeInterval); 
  }, [companyDetails.companyName]);

  const handleCollapseOnboarding = () => {
    setOnboardingHeight('0px');
    setTimeout(() => setOnboardingVisible(false), 500);
  };

  const handleToggleFeedType = (event) => {
    setFeedType(event.target.checked ? 'personal' : 'business');
  };

  return (
    <div className={styles.todayContainer}>
      {onboardingVisible && (
            <div className={styles.onboardingBox} style={{ height: onboardingHeight }}>
              <h1>Welcome to <u>Today</u></h1>
              <p className={styles.headline}>{headline}</p>
              <div className={styles.featureModules}>
                <div className={styles.module}>
                  <h2>Curated Stories</h2>
                  <p>We’ll highlight the most relevant emerging stories, pertinent topics, and interesting opportunities for {companyDetails.companyName}.</p>
                </div>
                <div className={styles.module}>
                  <h2>Personalized Strategy</h2>
                  <p>Using our knowledge of {companyDetails.companyName} and your goals, we’ll suggest strategic actions for how to timely and meaningfully engage.</p>
                </div>
                <div className={styles.module}>
                  <h2>Check back tomorrow</h2>
                  <p>Rally never sleeps. Tomorrow brings a new set of emerging content and unique, tailored opportunities.</p>
                </div>
              </div>
              <button onClick={handleCollapseOnboarding} className={styles.collapseButton}>&rarr; Let's go &larr;</button>
            </div>
          )}
          {!onboardingVisible && 
            <div className={styles.feedReport}>
              <TopicFeed collapsed={topicFeedCollapsed} setCollapsed={setTopicFeedCollapsed}>
                <div className={styles.toggleContainer}>
                <span className={styles.feedLabel}>{companyDetails.companyName}</span>
                <label className={styles.switch}>
                  <input type="checkbox" checked={feedType === 'personal'} onChange={handleToggleFeedType} />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.feedLabel}>Me</span>
              </div>
              </TopicFeed>
              <TopicReport topicFeedCollapsed={topicFeedCollapsed} />
            </div>
          }
    </div>
  );
};

export default Today;

import React from "react";
import StripeContext from './StripeContext';
import CheckoutForm from './CheckoutForm';

const InjectedCheckoutForm = () => {
  return (
    <StripeContext>
      <CheckoutForm />
    </StripeContext>
  );
};

export default InjectedCheckoutForm;

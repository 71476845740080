import React from 'react';
import styles from '../styles/onboarding/RallyHelp.module.scss'; // Ensure this SCSS file is correctly linked

const RallyHelp = ({ companyDetails, onNext }) => {

    const { goals, companyName } = companyDetails;

    const features = {
        "Establish The Brand": `${companyName} can harness Rally AI's expertise to activate its brand within the industry. We use advanced analytics to position your brand distinctively, ensuring it reflects your unique identity and resonates with your target market. Our AI-driven insights help you capitalize on emerging trends, solidifying your presence in the marketplace.`,
        
        "Market The Product": `With Rally AI, ${companyName} can execute precision-targeted marketing strategies that resonate within your industry. We analyze market dynamics and consumer behavior to tailor your messaging, ensuring your product not only reaches but also influences your desired audience at the right time and place.`,
        
        "Raise Funds": `Rally AI equips ${companyName} with critical insights to secure funding by aligning your business objectives with the interests of potential investors. Our strategic recommendations are designed to highlight how your company contributes to and shapes industry trends, making your business an attractive investment.`,
        
        "Influence The Audience": `For ${companyName}, influencing the audience is about more than just reach—it's about engagement that drives action. Rally AI's tools dissect audience data to develop a communication strategy that reflects your brand’s voice, amplifying your influence on ongoing industry conversations.`,
        
        "Expand Content Reach": `Rally AI enables ${companyName} to expand its content's reach with strategies optimized for impact within your industry. Our systems analyze engagement data and predict trends, helping you to produce and distribute content that not only reaches but also engages and influences your industry's discourse.`,
        
        "Interact with Key Players": `${companyName} can strategically interact with key industry players using insights provided by Rally AI. We identify influencers and thought leaders whose interests align with yours, facilitating connections that boost your brand's visibility and influence within the industry.`,
        
        "Monitor Industry News": `Stay ahead with Rally AI's real-time monitoring of industry news tailored for ${companyName}. We track the pulse of your industry, identifying emerging topics so you can swiftly respond or adapt your strategy, ensuring your business remains relevant and influential amid industry shifts.`,
        
        "Manage Social Media": `Rally AI transforms how ${companyName} manages its social media, turning every post into an opportunity for industry influence. Our tools optimize your social media strategy to ensure your voice is not just heard but also respected as a contributor to key discussions in your field.`
    };



    return (
        <div className={styles.container}>
            <h1 className={styles.title}>That's you. Here's us.</h1>
            <p className={styles.subtitle}>Rally is going to help <b>{companyName}</b>:</p>
            <div className={styles.features}>
                {goals.map(goal => (
                    <div key={goal} className={styles.featureBox}>
                        <h2 className={styles.featureTitle}>{goal}</h2>
                        <p className={styles.featureText}>{features[goal]}</p>
                    </div>
                ))}
            </div>
            <button onClick={onNext} className={styles.opportunitiesButton}>
                Show me Today's opportunities <span className={styles.rightArrow}>&rarr;</span>
            </button>
        </div>
    );
};

export default RallyHelp;

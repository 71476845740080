import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './styles/PasswordUpdate.module.scss';

function PasswordUpdate({ token }) {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const response = await axios.post('/password/reset', {
        token,
        password,
        password_confirmation: passwordConfirmation
      });
      if (response.status === 200) {
        setSuccess('Your password has been successfully reset.');
        setTimeout(() => {
          navigate('/login');
        }, 5000); // Redirect after 5 seconds
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      setError('Failed to reset password. Please make sure your token is valid.');
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        {error && <p className={styles.error}>{error}</p>}
        {success && <p className={styles.successMessage}>{success}</p>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New password"
          required
          className={styles.formControl}
        />
        <input
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          placeholder="Confirm new password"
          required
          className={styles.formControl}
        />
        <button type="submit" className={styles.buttonPrimary}>Reset Password</button>
      </form>
    </div>
  );
}

export default PasswordUpdate;

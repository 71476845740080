import React, { useState, useEffect } from 'react';
import { useLinkedSocials } from '../contexts/LinkedSocialsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter as faXTwitter, faLinkedin, faRedditAlien } from '@fortawesome/free-brands-svg-icons';
import styles from './styles/SocialLogin.module.scss';

const SocialLogin = ({ title, type }) => {
  const {
    linkedSocials,
    unlinkSocial,
    linkedCompanySocials,
    unlinkCompanySocial,
  } = useLinkedSocials();

  const [error, setError] = useState('');
  const [socialPlatforms, setSocialPlatforms] = useState([]);
  useEffect(() => {
    if (type == 'personal') {
      if (linkedSocials) {
        setInitialPlatforms(linkedSocials);
      }
    } else if (type == 'business') {
      if (linkedCompanySocials) {
        setInitialPlatforms(linkedCompanySocials);
      }
    }
  }, [linkedSocials, linkedCompanySocials, type]);

  const setInitialPlatforms = (socials) => {
    const platforms = [
      { name: 'Twitter', icon: faXTwitter, color: '#000', linked: socials.twitter, path: `/auth/twitter?state=${type}`, userName: socials.twitterUserName },
      { name: 'LinkedIn', icon: faLinkedin, color: '#0077B5', linked: socials.linkedin, path: `/auth/linkedin?state=${type}`, userName: socials.linkedinUserName },
      { name: 'Reddit', icon: faRedditAlien, color: '#FF5700', linked: socials.reddit, path: `/auth/reddit?state=${type}`, userName: socials.redditUserName},
    ];
    setSocialPlatforms(platforms);
  };

  const handleDisconnect = (platform) => {
    const unlinkFunction = type === 'business' ? unlinkCompanySocial : unlinkSocial;
    unlinkFunction(platform).catch(e => {
      setError(`Failed to disconnect ${platform}`);
    });
  };

  return (
    <div className={styles.socialLogin}>
      {title && <h2 className={styles.headline}>{title}</h2>}
      <div className={styles.socialList}>
        {socialPlatforms.map(platform => (
          <div key={platform.name} className={styles.platformItem}>
            <FontAwesomeIcon icon={platform.icon} size="2x" className={styles.socialLogo} style={{ color: platform.color }} />
            <div className={styles.platformActions}>
              {platform.linked ? (
                <div className={styles.connected}>
                  Connected
                  <button onClick={() => handleDisconnect(platform.name)} className={styles.disconnectButton}>
                    <span className={styles.xIcon}>&times;</span>
                  </button>
                  <span className={styles.userName}>
                  { platform.userName }
                  </span>
                </div>
              ) : (
                <a href={platform.path} className={styles.connectButton}>Connect</a>
              )}
            </div>
          </div>
        ))}
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

export default SocialLogin;

import React, { useState, useEffect } from 'react';
import styles from '../styles/topic_report/InTheHeadlines.module.scss';

const InTheHeadlines = ({ articles }) => {
  const [expandedArticle, setExpandedArticle] = useState(articles[0]?.id);

  useEffect(() => {
    setExpandedArticle(articles[0]?.id);
  }, [articles]);

  const handleArticleClick = (articleId) => {
    setExpandedArticle(expandedArticle === articleId ? null : articleId);
  };



  // debugger

  return (
    <div className={styles.inTheHeadlines}>
      <div className={styles.header}>In the Headlines</div>
      <ul className={styles.articleList}>
        {articles.map(article => (
          <li key={article.id} className={expandedArticle === article.id ? styles.expandedArticle : styles.articleItem} onClick={() => handleArticleClick(article.id)}>
            {expandedArticle === article.id ? (
              <>
                <a href={article.url} target="_blank"><div className={styles.image} style={{ backgroundImage: `url(${article.imageUrl || "https://i.ibb.co/M7wrqSc/Logo-Variant-Black-3x.jpg"})` }} /></a>
                <div className={styles.articleDetails}>
                  <div className={styles.urgencyTime}>
                    <span className={`${styles.urgency} ${styles[styleUrgency(article.urgency)]}`}>{getUrgencyEmoji(article.urgency)} {article.urgency}</span>
                    <span className={styles.time}>{timeSince(article.publishedAt)}</span>
                  </div>
                  <div className={styles.headline}>{article.title}</div>
                  <div className={styles.subHeadline}>{article.description}</div>
                  <div className={`${styles.sentiment} ${styles[article.sentimentText]}`}>
                    <span>{getSentimentEmoji(article.sentimentText)}</span><span>{article.sentimentText}</span> 
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.preview}>{article.title}</div>
                <div className={styles.publication}>{article.source} <span>{getSentimentEmoji(article.sentimentText)}</span></div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getSentimentEmoji = (sentiment) => {
  switch(sentiment) {
    case 'positive': return '😊';
    case 'negative': return '😡';
    default: return '😐';
  }
};
const getUrgencyEmoji = (urgency) => {
  switch (urgency) {
    case 'Breaking':
      return '🚨'; // Red light siren emoji for breaking news
    case 'Recent':
      return '⏳'; // Hourglass not done emoji for recent news
    case 'This Week':
      return '📅'; // Calendar emoji for news from this week
    case 'This Month':
      return '🗓️'; // Spiral calendar emoji for news from this month
    default:
      return '🔔'; // Bell emoji for general news
    }
}

const timeSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.round((now - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const months = Math.round(days / 30.44); // Average month length
    const years = Math.round(days / 365.25); // Account for leap years

    if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (hours < 24) {
        return `${hours} hours ago`;
    } else if (days < 30) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (months < 12) {
        return `${months} months ago`;
    } else {
        return `${years} years ago`;
    }
}


const styleUrgency = (urgency) => urgency.split(" ").join("").toLowerCase();


export default InTheHeadlines;

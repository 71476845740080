import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const StripeContext = ({ children }) => {
  const [clientSecret, setClientSecret] = useState('');
  const productId = process.env.REACT_APP_PRODUCT_ID //currently we are using hardcoded product id

  useEffect(() => { 
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post('/create_payment_intent', { product_id: productId });
        setClientSecret(response.data.client_secret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    fetchClientSecret();
  }, [productId]);

  return (
    clientSecret && (
      <Elements stripe={stripePromise} options={{ clientSecret, paymentMethodCreation: 'manual' }}>
        {children}
      </Elements>
    )
  );
};

export default StripeContext;

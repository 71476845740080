import React, { useState, useEffect, useCallback } from 'react';
import styles from '../styles/onboarding/WaitingScreen.module.scss';  // Assuming you have this SCSS file
import { useCompanyDetails } from '../../contexts/CompanyDetailsContext'; 

const WaitingScreen = ({ text, nextStep }) => {
  const { companyDetails, fetchCompanyDetails } = useCompanyDetails();
  const [counter, setCounter] = useState(0);

  // Using useCallback to ensure the identity of the function doesn't change on re-renders.
  const callFunction = useCallback(() => {
    setCounter((prevCounter) => {
      if (prevCounter < 12) {
        fetchCompanyDetails();
        
        // Check for company details condition outside state update
        if(companyDetails?.companyName) {
          if (nextStep) nextStep(4);
          return 12;  // Update counter to 3 immediately to stop further calls
        } else {
          return prevCounter + 1;
        }
      } else {
        return prevCounter;
      }
    });
  }, [companyDetails, fetchCompanyDetails, nextStep]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      callFunction();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [callFunction]);

  return (
    <div className={styles.container}>
      <div className={styles.spinner}></div>
      <p className={styles.message}>{text}</p>
    </div>
  );
};

export default WaitingScreen;

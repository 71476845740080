import React, { useEffect, useRef } from 'react';
import styles from '../styles/topic_report/OnTheWeb.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

const OnTheWeb = ({ posts }) => {
  const imgRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            observer.unobserve(img);
          }
        });
      }, 
      { threshold: 0.1 }
    );

    imgRefs.current.forEach(img => {
      if (img) observer.observe(img);
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  posts.sort((a, b) => b.reposts - a.reposts);

  return (
    <div className={styles.onTheWeb}>
      <h2 className={styles.title}>On The Web</h2>
      <div className={styles.postContainer}>
        {posts.map((post, index) => (
          <a key={post.id} href={post.url} target="_blank" rel="noopener noreferrer">
            <div className={styles.postBox}>
              <div className={styles.postHeader}>
                <img 
                  data-src={post.userAvatar} 
                  alt="user avatar" 
                  className={styles.avatar}
                  onError={(e) => e.target.src = 'https://rally-public-company-images.s3.amazonaws.com/logos/logo_mark.png'}
                  ref={el => imgRefs.current[index] = el} 
                />
                <strong className={styles.username}>{post.username}</strong>
                <FontAwesomeIcon icon={faXTwitter} className={styles.twitterIcon} />
              </div>
              <p className={styles.postBody}>{post.body}</p>
              <div className={styles.socialStats}>
                <span><b>{post.likes.toLocaleString()}</b> Likes</span>
                <span><b>{post.reposts.toLocaleString()}</b> Reposts</span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default OnTheWeb;

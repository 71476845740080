// contexts/LinkedSocialsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Assuming you have AuthContext that provides user info

const LinkedSocialsContext = createContext();

export const useLinkedSocials = () => useContext(LinkedSocialsContext);

export const LinkedSocialsProvider = ({ children }) => {
  const [linkedSocials, setLinkedSocials] = useState({
    twitter: false,
    facebook: false,
    linkedin: false,
    reddit: false
  });

const { currentUser } = useAuth(); // Get user info from AuthContext

const [linkedCompanySocials, setLinkedCompanySocials] = useState({
    twitter: false,
    facebook: false,
    linkedin: false,
    reddit: false
  });

  const fetchLinkedSocials = async () => {
    try {
      const response = await axios.get('/linked_socials?type=personal');
      setLinkedSocials(response.data);
    } catch (error) {
      console.error('Error fetching linked socials:', error);
    }
  };

  const fetchCompanyLinkedSocials = async () => {
    try {
      const response = await axios.get('/linked_socials?type=business');
      setLinkedCompanySocials(response.data);
    } catch (error) {
      console.error('Error fetching linked socials:', error);
    }
  };

  useEffect(() => {
    if (currentUser) { // Fetch linked socials only if the user is logged in
      fetchLinkedSocials();
      fetchCompanyLinkedSocials();
    }
  }, [currentUser]); // Re-fetch when user changes

  const value = { linkedSocials, setLinkedSocials, fetchLinkedSocials, linkedCompanySocials, fetchCompanyLinkedSocials };

  return <LinkedSocialsContext.Provider value={value}>{children}</LinkedSocialsContext.Provider>;
};

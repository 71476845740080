import Button from "../shared/Button.jsx";
import React, { useState} from 'react';
import axios from 'axios';
import { useFeed } from "../../contexts/FeedContext";

const CopyGenerator = ({ id, setText}) => {
  const [recommendationId, setRecommendationId] = useState(id);
  const [loading, setLoading] = useState(false);
  const { feedType } = useFeed();

  const generateCopy = async () => {
    try {
      setLoading(true)
      const response = await axios.post('/copy_generator', { id: recommendationId, type: feedType });
      if (response.status === 200) {
        setText(response.data.copy)
      } else {
        alert("Something went wrong with posting. Try refreshing the page. If the problem persists, contact tom@rally-ai.com.")
      }
    } catch (error) {
      console.error('Error executing content:', error);
    }
    finally {
      setLoading(false);
    }
  }

  
  return (
    <Button loading={loading} onClick={generateCopy} text="Generate Copy"/>
  )
}

export default CopyGenerator;
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Timeline} from '@mui/lab';
import { Container, Typography } from '@mui/material';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { addCompanyUpdates, fetchCompanyUpdates } from '../../api/dataApi';
import { handleErrorResponse } from '../../utils/handleErrorResponse';
import NewUpdateForm from './NewUpdateForm';
import UpdateItem from './UpdateItem';
import LoadingMessage from './LoadingMessage';
import EndMessage from './EndMessage';
import { useCompanyDetails } from '../../contexts/CompanyDetailsContext';
import styles from '../styles/BrandUpdates.module.scss';

const BrandUpdates = () => {
  const [items, setItems] = useState([]);
  const [newDescription, setNewDescription] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const queryClient = useQueryClient();
  const { companyDetails } = useCompanyDetails();

  const fetchItems = async (page) => {
    const response = await fetchCompanyUpdates(page);
    return response.data;
  };

  const { refetch } = useQuery(
    ['companyUpdates', page],
    () => fetchItems(page),
    {
      onSuccess: (data) => {
        setItems((prevItems) => [...prevItems, ...data]);
        if (data.length === 0) {
          setHasMore(false);
        }
      },
      onError: (err) => {
        handleErrorResponse(err?.errors);
      },
      keepPreviousData: true,
      enabled: false, // Disable automatic fetching
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  const addUpdates = useMutation(addCompanyUpdates, {
    onSuccess: (response) => {
      if (response.status === 201) {
        setItems([response.data, ...items]);
        setNewDescription('');
      } else {
        handleErrorResponse(response.data?.errors);
      }
    },
    onError: (err) => {
      handleErrorResponse(err?.errors);
    }
  });

  const handleAddItem = () => {
    addUpdates.mutate({ company_update: { description: newDescription } });
  };

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Container className={styles.brandUpdates}>
      <Typography variant="h3" component="h1" align="center" sx={{ color: '#000000', fontWeight: '800' }}>
        What’s new at {companyDetails.companyName}?
      </Typography>
      <Typography variant="h5" align="center" gutterBottom sx={{ marginTop: '15px', color: '#000000' }}>
        Share recent press and announcements to improve our insights.
      </Typography>

      <Timeline position="left" sx={{ width: '100%', maxWidth: '1000px', marginTop: '50px' }}>
        <NewUpdateForm
          newDescription={newDescription}
          setNewDescription={setNewDescription}
          handleAddItem={handleAddItem}
        />

        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<LoadingMessage />}
          endMessage={<EndMessage />}
        >
          {items.map((item, index) => (
            <UpdateItem key={index} item={item} index={index} totalItems={items.length} />
          ))}
        </InfiniteScroll>
      </Timeline>
    </Container>
  );
};

export default BrandUpdates;
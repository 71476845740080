import React, { useState, useEffect } from 'react';
import styles from '../styles/topic_report/Recommendations.module.scss';
import { faEnvelope, faNewspaper, faBell } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faLinkedin, faRedditAlien, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyExecutor from "../CopyExecutor";

const Recommendations = ({ recommendations }) => {
  const [expandedIds, setExpandedIds] = useState([]);

  const toggleExpansion = (id) => {
    const newArr = expandedIds.includes(id) ? expandedIds.filter(val => val !== id) : [...expandedIds, id];
    setExpandedIds(newArr);
  };

  useEffect(() => {
    setExpandedIds([]);
  }, [recommendations]);

  return (
    <div className={styles.recommendations}>
      <h2>Our Recommendations</h2>
      {recommendations.map((recommendation, index) => (
        <div key={recommendation.title} className={styles.recommendationItem}>
          <div className={styles.header}>
            <button onClick={() => toggleExpansion(index)} className={styles.expandButton}>
              {expandedIds.includes(index) ? "-" : "+"}
            </button>
            <span className={styles.title}>{recommendation.title}</span>
            <FontAwesomeIcon icon={icons[recommendation.domain] || faBell} className={styles.strategyIcon} />
          </div>
          <div
            className={
              expandedIds.includes(index)
                ? `${styles.detailSection} ${styles.detailSectionExpanded}`
                : `${styles.detailSection} ${styles.detailSectionCollapsed}`
            }
          >
            <p>{recommendation.explanation}</p>
            <CopyExecutor domain={recommendation.domain} copy={recommendation.copy} id={recommendation.id}/>
          </div>
        </div>
      ))}
    </div>
  );
};

const icons = {
  twitter: faXTwitter,
  linkedin: faLinkedin,
  reddit: faRedditAlien,
  facebook: faFacebook,
  email: faEnvelope,
  blog: faNewspaper
}

export default Recommendations;

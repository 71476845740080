import React, { useState } from 'react';
import styles from '../styles/brand_center/TuneRecommendations.module.scss'; // Ensure the CSS path is correct

const TuneRecommendations = ({ companyDetails, updateCompanyDetails }) => {
  const goals = [
    "Establish The Brand", "Market The Product", "Raise Funds", 
    "Influence The Audience", "Expand Content Reach", "Interact with Key Players", 
    "Monitor Industry News", "Manage Social Media"
  ];

  const [selectedGoals, setSelectedGoals] = useState(new Set(companyDetails.goals));

  const toggleGoal = (goal) => {
    const newSet = new Set(selectedGoals);
    if (newSet.has(goal)) {
      newSet.delete(goal);
    } else {
      newSet.add(goal);
    }
    setSelectedGoals(newSet);

    updateCompanyDetails({goals: Array.from(newSet)});

  };

  return (
    <div className={styles.tuneRecommendations}>
      <h1>Tune Recommendations</h1>
      <p>Tell us about your goals, and get meaningful, <i>immediately</i> actionable next steps toward them every day:</p>
      <div className={styles.grid}>
        {goals.map(goal => (
          <div
            key={goal}
            className={`${styles.box} ${selectedGoals.has(goal) ? styles.selected : ''}`}
            onClick={() => toggleGoal(goal)}
          >
            {goal}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TuneRecommendations;

import React, { useState, useEffect } from 'react';
import styles from '../styles/onboarding/CompanyURLStep.module.scss'; // Ensure you have this import for styles

const CompanyURLStep = ({ onSubmit }) => {
    const [headline, setHeadline] = useState('');
    const [url, setUrl] = useState('');

    const fullHeadline = `Enter Your Company URL`

    useEffect(() => {
        let typeInterval;

        setTimeout(() => {
            let charIndex = -1;
            const totalDuration = 3000; // Total time for the typewriter effect in milliseconds
            const intervalTime = totalDuration / fullHeadline.length; // Time per character

            typeInterval = setInterval(() => {
              // debugger
              charIndex++;
              if (charIndex >= fullHeadline.length) {
                clearInterval(typeInterval);
                return;
              }
              setHeadline((prev) => prev + fullHeadline[charIndex]);
            }, intervalTime);
        }, 3000);

        return function(){clearInterval(typeInterval)}; 
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(`https://${url}`);
    };

    return (
        <div className={styles.container}>
            <img src="https://rally-public-company-images.s3.amazonaws.com/logos/logo_mark.png" alt="Logo" className={styles.logo} />
            <h1 className={styles.title}>{headline}</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputBox}>
                    <span className={styles.prefix}>https://</span>
                    <input 
                        type="text" 
                        value={url} 
                        onChange={e => setUrl(e.target.value)}
                        className={styles.input}
                        placeholder="" 
                        autoFocus
                    />
                    <button type="submit" className={styles.analyzeButton}>Analyze</button>
                </div>
            </form>
        </div>
    );
};

export default CompanyURLStep;

import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCompanyDetails } from "../contexts/CompanyDetailsContext";
import { useUserDetails } from "../contexts/UserDetailsContext";
import styles from "./styles/BrandCenter.module.scss";
import CompanyBrand from "./brand_center/CompanyBrand";
import ConnectAccounts from "./brand_center/ConnectAccounts";
import TuneRecommendations from "./brand_center/TuneRecommendations";
import AboutMe from "./brand_center/AboutMe";
import Uploader from "./shared/Uploader";
import Spinner from "./shared/Spinner";

const BrandCenter = () => {
  const { companyDetails, updateCompanyDetails } = useCompanyDetails();
  const { userDetails, updateUserDetails } = useUserDetails();
  const { section } = useParams();
  const [logoLoading, setLogoLoading] = useState(false);
  const [secLogoLoading, setSecLogoLoading] = useState(false);

  const preselectedSection = {
    company: "Company & Brand",
    social: "Connect Accounts",
    recommendations: "Tune Recommendations",
    about: "About Me",
  }[section];
  const [selectedSection, setSelectedSection] = useState(
    preselectedSection || "Company & Brand",
  );

  const sections = {
    "Company & Brand": (
      <CompanyBrand
        companyDetails={companyDetails}
        updateCompanyDetails={updateCompanyDetails}
      />
    ),
    "Connect Accounts": <ConnectAccounts />,
    "Tune Recommendations": (
      <TuneRecommendations
        companyDetails={companyDetails}
        updateCompanyDetails={updateCompanyDetails}
      />
    ),
    "About Me": (
      <AboutMe
        userDetails={userDetails}
        updateUserDetails={updateUserDetails}
      />
    ),
  };

  const [logoImageUrl, setLogoImageUrl] = useState();
  const [splashImageUrl, setSplashImageUrl] = useState();

  useEffect(() => {
    setLogoImageUrl(companyDetails.logoUrl);
    setSplashImageUrl(companyDetails.secondaryImageUrl);
  }, [companyDetails]);

  return (
    <div className={styles.brandCenter}>
      <div className={styles.images}>
        <div
          className={styles.imageLarge}
          style={{
            backgroundImage: splashImageUrl ? `url(${splashImageUrl})` : "none",
          }}
        >
          {secLogoLoading && <Spinner width="50" height="50" />}
          <Uploader
            setLogoImageUrl={setLogoImageUrl}
            setSplashImageUrl={setSplashImageUrl}
            fileType="secondary_image"
            websiteDetailId={companyDetails.websiteDetailId}
            loading={secLogoLoading}
            setLoading={setSecLogoLoading}
          />
        </div>
        <div
          className={styles.imageSmall}
          style={{
            backgroundImage: `url(${logoImageUrl})`,
            backgroundColor:
              logoImageUrl && logoImageUrl.includes("svg")
                ? "lightgrey"
                : companyDetails.primaryColor,
          }}
        >
          {logoLoading && <Spinner width="50" height="50" />}

          <Uploader
            setLogoImageUrl={setLogoImageUrl}
            setSplashImageUrl={setSplashImageUrl}
            fileType="logo"
            websiteDetailId={companyDetails.websiteDetailId}
            loading={logoLoading}
            setLoading={setLogoLoading}
          />
        </div>
      </div>
      <div className={styles.navContent}>
        <div className={styles.navSection}>
          {Object.keys(sections).map((section) => (
            <div
              key={section}
              className={`${styles.navItem} ${selectedSection === section ? styles.active : ""}`}
              onClick={() => setSelectedSection(section)}
            >
              {section}
            </div>
          ))}
        </div>
        <div className={styles.content}>{sections[selectedSection]}</div>
      </div>
    </div>
  );
};

export default BrandCenter;

import React from 'react';
import styles from '../styles/topic_report/Strategy.module.scss';

const Strategy = ({ strategy }) => {
  return (
    <div className={styles.strategy}>
      <h2>The Strategy</h2>
      <p><b>{strategy.headline}</b></p>
      <p>{strategy.explanation}</p>
    </div>
  );
};

export default Strategy;

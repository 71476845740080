import React from 'react';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Paper, Typography, Box, TextField } from '@mui/material';
import Button from '../shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';

const NewUpdateForm = ({ newDescription, setNewDescription, handleAddItem }) => {
  const icon = (<FontAwesomeIcon icon={faArrowAltCircleDown} />);
  const textWithIcon = (
    <span>
      Share With Rally {icon}
    </span>
  );

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0.1, paddingRight: '8px' }}>
        <Typography variant="subtitle1" color="#000000">
          Today
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {new Date().getFullYear()}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ color: "#000000", backgroundColor: "#000000" }} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ flex: 0.9, display: 'flex', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '16px', display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ marginRight: '16px', flexShrink: 0 }}>
            <img src="https://rally-public-company-images.s3.amazonaws.com/logos/logo_mark.png" alt="Logo" width={25} height={25} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <TextField
              variant="standard"
              fullWidth
              placeholder="What's happening?"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              sx={{
                marginRight: '16px',
                '& .MuiInputBase-root:before': { borderBottom: 'none' },
                '& .MuiInputBase-root:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                '& .MuiInputBase-root:after': { borderBottom: 'none' }
              }}
            />
            <Button sx={{ marginTop: '10px' }} onClick={handleAddItem} text={textWithIcon} aria-label="Share With Rally" />
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default NewUpdateForm;

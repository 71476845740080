// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adFlyiRKt76jalGKFsLc{position:absolute;top:10px;right:10px;background-color:rgba(255,255,255,.7);border-radius:50%;padding:10px;cursor:pointer;display:flex;justify-content:center;align-items:center}.adFlyiRKt76jalGKFsLc:hover{background-color:#fff}.adFlyiRKt76jalGKFsLc[disabled]{cursor:not-allowed;background-color:rgba(255,255,255,.7);pointer-events:none}.adFlyiRKt76jalGKFsLc[disabled] input[type=file]{display:none}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/Uploader.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,qCAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4BACE,qBAAA,CAGF,gCACE,kBAAA,CACA,qCAAA,CACA,mBAAA,CAKJ,iDACE,YAAA","sourcesContent":[".uploadIcon {\n  position: absolute;\n  top: 10px; /* Adjust as needed */\n  right: 10px; /* Adjust as needed */\n  background-color: rgba(255, 255, 255, 0.7);\n  border-radius: 50%;\n  padding: 10px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 1);\n  }\n\n  &[disabled] {\n    cursor: not-allowed;\n    background-color: rgba(255, 255, 255, 0.7);\n    pointer-events: none; // Prevent clicks when disabled\n  }\n}\n\n// Hide input file when disabled\n.uploadIcon[disabled] input[type=\"file\"] {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadIcon": `adFlyiRKt76jalGKFsLc`
};
export default ___CSS_LOADER_EXPORT___;

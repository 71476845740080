// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sm1vNJniFW7ruaPlls6_{flex-grow:1;background-color:#ebf3ff;border-top-left-radius:8px;padding:20px;justify-content:start}.Sm1vNJniFW7ruaPlls6_ h2{font-size:24px;margin-bottom:10px;color:#1462d4}.Sm1vNJniFW7ruaPlls6_ p{font-size:16px;line-height:1.5}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/topic_report/Strategy.module.scss","webpack://./app/javascript/components/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,wBCMgB,CDLhB,0BAAA,CACA,YAAA,CACA,qBAAA,CAEA,yBACE,cAAA,CACA,kBAAA,CACA,aCTQ,CDYV,wBACE,cAAA,CACA,eAAA","sourcesContent":["@import '../variables';\n\n.strategy {\n  flex-grow: 1;\n  background-color: $background-blue;\n  border-top-left-radius: 8px;\n  padding: 20px;\n  justify-content: start;\n\n  h2 {\n    font-size: 24px;\n    margin-bottom: 10px;\n    color: $font-blue;\n  }\n\n  p {\n    font-size: 16px;\n    line-height: 1.5;\n  }\n}\n","$white-background: #ffffff;\n$black-background: #000000;\n$font-bold: #170E25;\n$font-blue: #1462D4;\n$font-grey: #D0D0D0;\n$font-white: #ffffff;\n$font-red: #A64206;\n$font-orange: #C86026;\n$line-grey: #D9D9D9;\n$background-red: #FFF2EB;\n$background-blue: #EBF3FF;\n$background-yellow: #FEF9DA;\n$red: #E84260;\n$green: #0E574A;\n$light-green: #E3F9F5;\n$blue: #326A92;\n$burnside-blue: #1462D4;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strategy": `Sm1vNJniFW7ruaPlls6_`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faCog, faCaretDown, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './styles/CopyExecutor.module.scss';
import { useLinkedSocials } from '../contexts/LinkedSocialsContext';
import { useFeed } from '../contexts/FeedContext';
import CopyGenerator from './recommendations/CopyGenerator';

const CopyExecutor = ({ domain, copy, id }) => {
  const { feedType } = useFeed();
  const { linkedSocials, linkedCompanySocials } = useLinkedSocials();
  
  const [filteredLabels, setFilteredLabels] = useState();
  const [postingOptions, setPostingOptions] = useState();
  
  const [text, setText] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Email to me');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [copied, setCopied] = useState(false);
  const [recommendationId, setRecommendationId] = useState(id);

  useEffect(() => {
    setText(copy);
  }, [copy]);

  useEffect(() => {
    let updatedFilteredLabels;
    if(feedType === 'personal'){
      updatedFilteredLabels = filterLabels(labels, linkedSocials || {});
    } else if(feedType === 'business'){
      updatedFilteredLabels = filterLabels(labels, linkedCompanySocials || {});
    }
    setFilteredLabels(updatedFilteredLabels);
    setPostingOptions(Object.values(updatedFilteredLabels));
    setButtonLabel(updatedFilteredLabels[domain] || 'Email to me');
  }, [feedType, linkedSocials, linkedCompanySocials, domain]);

  const refreshText = async () => {
    try {
      const response = await axios.get('/copy_generator');
      setText(response.data);
      setCopied(false);
    } catch (error) {
      console.error('Error fetching text:', error);
    }
  };

  const handleDropdown = (label) => {
    setButtonLabel(label);
    setShowDropdown(false);
  };

  const executeContent = async () => {
    setIsDisabled(true);
    try {
      const selectedDomain = Object.keys(filteredLabels).find(key => filteredLabels[key] === buttonLabel);
      const response = await axios.post('/execute_content', { content: text, domain: selectedDomain, type: feedType });
      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert("Something went wrong with posting. Try refreshing the page. If the problem persists, contact tom@rally-ai.com.");
      }
    } catch (error) {
      console.error('Error executing content:', error);
    }
    setIsDisabled(false);
  };

  const copyToClipboard = async () => {
    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    } else {
      console.error('Clipboard API not available');
    }
  };

  const { innerBorderRef } = useOnOutsideClick(() => setShowDropdown(false));

  // Monitor textarea changes to reset the copy icon
  useEffect(() => {
    if (text && copied) {
      setCopied(false);
    }
  }, [text]);

  return (
    <div className={styles.container}>
      {text ? (
        <>
          <div className={styles.textareaContainer}>
                        <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              className={styles.textarea}
              disabled={isDisabled}
            />
            <div className={styles.icons}>
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} className={styles.icon} onClick={copyToClipboard} />
              <FontAwesomeIcon icon={faRefresh} className={styles.icon} onClick={refreshText} />
              <FontAwesomeIcon icon={faCog} className={styles.icon} onClick={() => setShowSettingsDropdown(!showSettingsDropdown)} />
              {showSettingsDropdown && (
                <div className={styles.settingsDropdown} onBlur={() => setShowSettingsDropdown(false)}>
                  <div><NavLink to="/brand-center">Brand Center</NavLink></div>
                  <div><NavLink to="/settings">Settings</NavLink></div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.actionButton}>
            <span className={styles.actionLabel} onClick={!isDisabled ? executeContent : undefined}>{buttonLabel}</span>
            <div className={styles.separator}></div>
            <div className={styles.dropdownIconContainer} onClick={() => setShowDropdown(!showDropdown)}>
              <FontAwesomeIcon className={styles.dropdownIcon} icon={faCaretDown} />
              {showDropdown && (
                <div className={styles.dropdown} ref={innerBorderRef}>
                  {postingOptions.map(option => (
                    <div key={option} onClick={() => handleDropdown(option)}>{option}</div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <CopyGenerator setText={setText} id={recommendationId} />
      )}
    </div>
  );
};

const labels = {
  linkedin: "Post to LinkedIn",
  twitter: "Post to Twitter",
  reddit: "Post to Reddit",
  email: "Email to me"
};

const useOnOutsideClick = handleOutsideClick => {
  const innerBorderRef = useRef();
  const useMountEffect = fun => useEffect(fun, []);

  const onClick = event => {
    if (
      innerBorderRef.current &&
      !innerBorderRef.current.contains(event.target)
    ) {
      handleOutsideClick();
    }
  };

  useMountEffect(() => {
    document.addEventListener("click", onClick, true);
    return () => {
      document.removeEventListener("click", onClick, true);
    };
  });

  return { innerBorderRef };
};

const filterLabels = (labels, linked) => (
  Object.fromEntries(
    Object.entries(labels).filter(([key]) => linked[key])
  )
);

export default CopyExecutor;

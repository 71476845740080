import React, { useState, useEffect } from "react";
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from "../styles/payments/CheckoutForm.module.scss";
import Button from "../shared/Button.jsx";
import { useAuth } from "../../contexts/AuthContext";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCompanyDetails } from '../../contexts/CompanyDetailsContext';

const CheckoutForm = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const { companyDetails } = useCompanyDetails();
  const [promoCode, setPromoCode] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (stripe && elements) {
      setLoading(false);
    }
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      // Ensure elements.submit() is called before creating the payment method
      const result = await elements.submit();
      if (result.error) {
        console.error('Failed to submit card details:', result.error.message);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            email: currentUser.email,
          }
        }
      });
      setPaymentProcessing(true)

      if (error) {
        console.error('Error creating payment method:', error);
      } else {
        console.log('Payment method created:', paymentMethod);

        // Handle payment method creation, e.g., save paymentMethod.id to state
        try {
          const response = await axios.post('/subscriptions', {
            source: paymentMethod.id,
            promotion_code: promoCode,
            price_id: process.env.REACT_APP_PRICE_ID //currently we are using hardcoded price id
          });

          if (response.status === 201) {
            console.log('Subscription created:', response.data);
            navigate(`/today?onboarding=true`);
          } else {
            console.error('Subscription creation failed:', response.data.error);
          }
        } catch (err) {
          console.error('Error creating subscription:', err);
        }
        finally{
          setPaymentProcessing(false)
        }
      }
    } catch (error) {
      console.error('Error submitting payment:', error);
    }
    finally{
      setPaymentProcessing(false)
    }
  };

  const handleChange = (e) => {
    setPromoCode( e.target.value );
  };


  return (
    <div className={styles.formContainer}>
      {paymentProcessing ? (
        <div className={styles.processing}>
          <h2><FontAwesomeIcon icon={faSpinner} spin /> Processing your payment, please wait...</h2>
        </div>
      ) : (
          <>
            {data.lapsed && (
              <div className={styles.alert}>
                <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />
                Your subscription has been lapsed. Please re-subscribe.
              </div>
            )}
            <form onSubmit={handleSubmit} className={styles.checkoutForm}>
              <h2> Rally AI Payment $295/Month</h2>
              <PaymentElement />
              <input
                name="promo code"
                placeholder="Enter Promo code"
                value={promoCode}
                className={styles.formControl}
                onChange={handleChange}
              />
              <Button type="submit" disabled={loading} text="Submit" />
            </form>
          </>
        )}
    </div>
  );
};

export default CheckoutForm;

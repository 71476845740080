import React, { useState, useNavi } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import styles from './styles/Navbar.module.scss';
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <img src="https://i.ibb.co/M7wrqSc/Logo-Variant-Black-3x.jpg" alt="Logo" />
      </div>
      {
        currentUser && !pathname.includes('onboarding') &&
        <>
          <div className={styles.navigation}>
            <NavLink to="/today" className={isActiveStyles}>Today</NavLink>
            <NavLink to="/brand-updates" className={isActiveStyles}>Brand Updates</NavLink>
            <NavLink to="/brand-center" className={isActiveStyles}>Brand Center</NavLink>
          </div>
          <div className={styles.settings} onClick={() => setDropdownOpen(!dropdownOpen)}>
            <FontAwesomeIcon icon={faCog} size="lg" />
            {dropdownOpen && (
              <div className={styles.dropdown}>
                <button onClick={() => navigate("/settings")}>Settings</button>
                <button onClick={handleLogout}>Logout</button>
              </div>
            )}
          </div>
        </>
      }

    </div>
  );
};

export default NavBar;

function isActiveStyles({ isActive, isPending, isTransitioning }) {
  return [
    isPending ? styles.link : styles.link,
    isActive ? `${styles.link} ${styles.active}` : styles.link,
    isTransitioning ? `${styles.link} ${styles.active}` : styles.link,
  ].join(" ");
}

// app/javascript/application.js

import '../assets/stylesheets/application.scss';

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: "https://9282587f2ab9a46ff4c0f8c28ce07ea0@o4507450672087040.ingest.us.sentry.io/4507450672218112",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/app.rally-ai\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener("DOMContentLoaded", () => {
  // set up defaults for axios requests
  axios.defaults.headers.common['Accept'] = 'application/json';


  const root = ReactDOM.createRoot(document.getElementById('react-root'));
  root.render(<App />);
});

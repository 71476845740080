import axios from 'axios';

export const createUser = (userData) => axios.post('/users', { user: userData });

export const loginUser = (credentials) => axios.post('/users/sign_in', { user: credentials });

export const forgotPassword = (userData) => axios.post('/password/forgot', userData );

export const fetchCompanyUpdates = (page) => axios.get(`/company_updates?page=${page}`);

export const addCompanyUpdates = (companyUpdates) => axios.post('/company_updates', companyUpdates );

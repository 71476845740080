import React from 'react';

const EndMessage = () => {
  return (
    <p style={{ textAlign: 'center' }}>
      <b>You have seen it all</b>
    </p>
  );
};

export default EndMessage;

import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  return currentUser ? <Navigate to="/today" replace state={{ from: location }} />  : <Outlet />;
};

export default AuthRoute;
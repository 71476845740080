import React, {useEffect} from 'react';
import SocialLogin from '../SocialLogin'; // Adjust the import path as necessary
import styles from "../styles/brand_center/ConnectAccounts.module.scss";
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { handleErrorResponse } from '../../utils/handleErrorResponse';

const ConnectAccounts = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.error) {
      handleErrorResponse(queryParams.error)
    }
  }, [location.search]);



  return (
    <div className={styles.connectAccounts}>
      <h2>Connect Accounts</h2>
      <p>Connect your accounts for twice the benefits: cutting-edge recommendations and instant execution. </p>
      <div className={styles.container}>
        <div className={styles.loginContainer}>
          <SocialLogin hideTitle={true} title={'Personal Accounts:'} type={'personal'}/>
        </div>
        <div className={styles.loginContainer}>
          <SocialLogin hideTitle={true} title={'Business Accounts:'} type={'business'}/>
        </div>
      </div>
    </div>
  );
};

export default ConnectAccounts;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles/PromoLandingPage.module.scss'; // Importing modular SCSS

const PromoLandingPage = () => {
    const targetDate = new Date('2024-05-10T12:00:00Z');
    const [timeLeft, setTimeLeft] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const difference = targetDate - now;
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            setTimeLeft(`${days} days ${hours} hrs ${minutes} mins ${seconds} secs`);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/registration', { email });
            console.log(response.data); // Process response data as needed
            setSubmitted(true);
            setError('');
        } catch (err) {
            console.error('Error registering:', err);
            setError(err.response?.data?.error || 'An unexpected error occurred.');
            setSubmitted(false);
        }
    };

    return (
        <div className={styles.promoContainer}>
            <h1>Countdown to Launch!</h1>
            <p className={styles.countdown}>{timeLeft}</p>
            {!submitted ?
                <form onSubmit={handleSubmit} className={styles.emailForm}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email for a 14-day trial"
                        required
                        className={styles.input}
                    />
                    <button type="submit" className={styles.button}>Sign Up</button>
                </form>
            : <p>Thank you! We'll send your promo code soon.</p>}
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default PromoLandingPage;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styles from './styles/Login.module.scss';
import { handleErrorResponse, handleSuccessResponse } from '../utils/handleErrorResponse';
import { loginUser, forgotPassword } from '../api/dataApi';
import { useMutation } from 'react-query';
import Button from './shared/Button';

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const { login } = useAuth();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const userLogin = useMutation(loginUser, {
    onSuccess: (response) => {
      if (response.status == 200 && response.headers.authorization) {
        login(response);
      }
      else {
        handleErrorResponse(response.response.data.error)
      }
    },
    onError: (err) => {
      handleErrorResponse(err.errors)
    }
  });

  const userForgotPassword = useMutation(forgotPassword, {
    onSuccess: (response) => {
      if (response.status == 200) {
        handleSuccessResponse('Check your email for reset instructions.')
      }
      else {
        handleErrorResponse(response.response.data.error)
      }
    },
    onError: (err) => {
      handleErrorResponse(err.errors)
    }
  });
  
  const handleSubmit = async (e, type) => {
    e.preventDefault();

    if (!credentials.email) {
      handleErrorResponse(`Please enter your email to ${type}.`)
      return;
    }

    if(type === 'login'){
      userLogin.mutate(credentials);
    }
    else if (type==='reset password'){
      userForgotPassword.mutate(credentials);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Login</h2>
      <form className={styles.loginForm}>
        <div className={styles.formGroup}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={credentials.email}
            onChange={handleChange}
            className={styles.formControl}
          />
        </div>
        <div className={styles.formGroup}>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={credentials.password}
            onChange={handleChange}
            className={styles.formControl}
          />
        </div>
        <Button className={styles.buttonPrimary} onClick={(event) => handleSubmit(event, 'login')}  disabled={userLogin.isLoading} loading={userLogin.isLoading} text="Login" aria-label="Login" />
        <Button className={styles.buttonSecondary} onClick={(event) => handleSubmit(event, 'reset password')} disabled={userForgotPassword.isLoading} loading={userForgotPassword.isLoading} text="Forgot Password?" aria-label="Forgot Password?" />
        <p className={styles.toggleAuth}>Need to register? <NavLink to="/auth/signup">Sign up</NavLink></p>
      </form>
    </div>
  );
};

export default Login;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jUl8hLItHsyx92tKe0HP{background-color:#f4f4f4;padding:20px 0;text-align:center;border-top:1px solid #ddd}.jUl8hLItHsyx92tKe0HP a{color:#007bff;text-decoration:none}.jUl8hLItHsyx92tKe0HP a:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/Footer.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,yBAAA,CAEA,wBACE,aAAA,CACA,oBAAA,CAEA,8BACE,yBAAA","sourcesContent":[".footer {\n  background-color: #f4f4f4;\n  padding: 20px 0;\n  text-align: center;\n  border-top: 1px solid #ddd;\n\n  a {\n    color: #007bff;\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `jUl8hLItHsyx92tKe0HP`
};
export default ___CSS_LOADER_EXPORT___;

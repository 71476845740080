import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { handleErrorResponse } from '../utils/handleErrorResponse';

const FeedContext = createContext();

export const useFeed = () => useContext(FeedContext);

export const FeedProvider = ({ children }) => {
  const [feedType, setFeedType] = useState('business');  // 'business' or 'personal'
  const [topic, setTopic] = useState(null);
  const [date, setDate] = useState(new Date());
  const [feedsData, setFeedsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFeedData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/feed`, {
        params: { date, feed_type: feedType }
      });

      if (response.data) {
        setFeedsData(prevData => ({
          ...prevData,
          [date]: {[feedType]: response.data.feed, ...prevData[date]}
        }));
        setTopic(response.data.feed[0]);
      }
      else{
        setTopic(null)
        setFeedsData({});
        handleErrorResponse(response.response.data?.error)

      }
    } catch (err) {
      setError(err.message);
      setFeedsData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!feedsData[date] || !feedsData[date][feedType]) {
      fetchFeedData();
    } else {
      setTopic(feedsData[date][feedType][0]);
    }
  }, [date, feedType]);

  const value = { feedType, setFeedType, date, setDate, setTopic, topic, feedsData, feed: feedsData[date] ? feedsData[date][feedType] : null, fetchFeedData, loading, error };

  return (
    <FeedContext.Provider value={value}>
      {children}
    </FeedContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import styles from '../styles/onboarding/SiteInsights.module.scss'; // Import the corresponding SCSS file
import RallyHelp from './RallyHelp';

const SiteInsights = ({ companyDetails, setCompanyDetails, onNext }) => {
    const [currentDetailIndex, setCurrentDetailIndex] = useState(0);
    const [editMode, setEditMode] = useState(false); // State to manage edit mode
    const [editedText, setEditedText] = useState(""); // State to store the edited text
    const [animate, setAnimate] = useState(false);
    const [showHelp, setShowHelp] = useState(false);

    const details = [
        {
            title:"Description",
            key: "description",
            text: companyDetails["description"],
        },
        {
            title: "Brand Identity", 
            key: "brandIdentity",
            text: companyDetails["brandIdentity"]
        },{
            title: "Brand Voice",
            key: "brandVoice",
            text: companyDetails["brandVoice"]
        }
    ];

    useEffect(() => {
        setTimeout(() => setAnimate(true), currentDetailIndex === 0 ? 3500 : 300);
        setEditedText(details[currentDetailIndex].text); // Initialize with current details text
    }, [currentDetailIndex]); // Ensure it updates when details or index changes

    const detailsItem = details[currentDetailIndex];

    const handleNextDetail = () => {
        setAnimate(false);
        setTimeout(() => {
            setCurrentDetailIndex((currentDetailIndex + 1) % details.length);
            setEditMode(false); // Exit edit mode on change
            setTimeout(() => setAnimate(true), 300);

            if (currentDetailIndex >= details.length - 1) {
              setShowHelp(true);
            };
        }, 300);

    };

    const handleEditToggle = () => {
        if (editMode) {
            setCompanyDetails(prevFields => ({
                    ...companyDetails,
                    [detailsItem.key]: editedText
                })
            )
        }
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setEditedText(e.target.value);
    };

    // debugger
    return (
        <div className={styles.container}>
            <div className={styles.images}>
                <div className={styles.imageLarge} style={{ backgroundImage: `url(${companyDetails.secondaryImageUrl})`, backgroundColor: companyDetails.secondaryColor}} />
                <div className={styles.imageSmall} style={{ backgroundImage: `url(${companyDetails.logoUrl})`, backgroundColor: companyDetails.logoUrl.includes('svg') ? "lightgrey" : companyDetails.primaryColor }} />
            </div>
            <div className={`${styles.detailsContainer} ${animate ? styles.fadeInUp : ''}`}>
              {
                !showHelp ?
                <>
                  <h2 className={styles.title}>{detailsItem.title}</h2>
                  {editMode ? (
                      <textarea
                          value={editedText}
                          onChange={handleChange}
                          className={styles.editableText}
                          autoFocus
                      />
                  ) : (
                      <p className={styles.text}>{editedText}</p>
                  )}
                  <div className={styles.actions}>
                      <button onClick={handleNextDetail} className={styles.thumbsUp}>
                          👍
                      </button>
                      <button onClick={handleEditToggle} className={styles.makeChange}>
                          {editMode ? "Save" : "Make a change"}
                      </button>
                  </div>
                </> :
                <RallyHelp onNext={onNext} companyDetails={companyDetails} />
              }
                
            </div>
        </div>
    );
};

export default SiteInsights;


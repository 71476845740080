import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from "../components/shared/Spinner";

const UserDetailsContext = createContext();

export const useUserDetails = () => useContext(UserDetailsContext);

export const UserDetailsProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('/user_details');
      setUserDetails(response.data);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserDetails = async (newDetails) => {
    try {
      const response = await axios.patch('/user_details', {user_detail: newDetails}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setUserDetails(response.data);
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const value = {
    userDetails,
    updateUserDetails,
    fetchUserDetails,
    setUserDetails
  };

  if (loading) {
    return <Spinner  width="50" height="50" marginTop="15%"/>;
  }

  return <UserDetailsContext.Provider value={value}>{children}</UserDetailsContext.Provider>;
};

import React, { useState } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';
import { useMutation } from 'react-query';
import styles from './styles/Signup.module.scss'; // Ensure your path is correct
import Button from './shared/Button';
import { handleErrorResponse } from '../utils/handleErrorResponse';
import { createUser } from '../api/dataApi';

const Signup = () => {
  const [userData, setUserData] = useState({ email: '', password: '', passwordConfirmation: '' });
  const { login } = useAuth();
  const navigate = useNavigate();

  const onboardUser = useMutation(createUser, {
    onSuccess: (response) => {
      if (response.status == 201 && response.headers.authorization) {
        login(response);
        navigate('/onboarding');
      }
      else {
        handleErrorResponse(response.response.data?.errors)
      }
    },
    onError: (err) => {
      handleErrorResponse(err?.errors)
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userData.password !== userData.passwordConfirmation) {
      handleErrorResponse('Passwords do not match.')
      return;
    }

    onboardUser.mutate(userData);
  };

  return (
    <div className={styles.formContainer}>
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit} className={styles.signupForm}>
        {['email', 'password', 'passwordConfirmation'].map((field) => (
          <div key={field} className={styles.formGroup}>
            <input
              type={field === 'email' ? 'email' : 'password'}
              name={field}
              placeholder={field.charAt(0).toUpperCase() + field.slice(1).replace('Confirmation', ' Confirmation')}
              value={userData[field]}
              onChange={handleChange}
              className={styles.formControl}
              aria-label={field.charAt(0).toUpperCase() + field.slice(1).replace('Confirmation', ' Confirmation')}
              aria-required="true"
            />
          </div>
        ))}

        <p className={styles.agreementText}>
          By clicking below, I agree to Rally's <a href="/terms-of-service.pdf" target="_blank">Terms of Service</a> and <a href="/privacy-policy-v2.pdf" target="_blank">Privacy Policy</a>.
        </p>
        <Button className={styles.buttonPrimary} disabled={onboardUser.isLoading} loading={onboardUser.isLoading} text="Sign Up" aria-label="Sign Up" />
        <p className={styles.toggleAuth}>Already have an account? <NavLink to="/auth/login">Log in</NavLink></p>
      </form>
    </div>
  );
};

export default Signup;

import React, { useState, useEffect } from 'react';
import styles from '../styles/brand_center/CompanyBrand.module.scss';

const CompanyBrand = ({ companyDetails, updateCompanyDetails }) => {
  const [editableData, setEditableData] = useState({});
  const [editField, setEditField] = useState(null);

  useEffect(() => {
    setEditableData(companyDetails);
  }, [companyDetails]);

  const handleTextChange = (field) => (event) => {
    setEditableData({ ...editableData, [field]: event.target.value });
  };

  const handleBlur = (field) => {
    console.log(`Data saved for ${field}:`, editableData[field]);
    updateCompanyDetails(editableData);
    setEditField(null);  // Exit edit mode on blur
  };

  const handleClick = (field) => {
    setEditField(field);
  };

  const renderField = (field, value) => {
    if (editField === field) {
      return (
        <textarea
          value={value}
          onChange={handleTextChange(field)}
          onBlur={() => handleBlur(field)}
          className={styles.editableText}
          autoFocus
        />
      );
    } else {
      return (
        <p onClick={() => handleClick(field)} className={styles.editableParagraph}>
          {value}
        </p>
      );
    }
  };

  // debugger

  return (
    <div className={styles.companyBrand}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h1 className={styles.summaryTitle}>Company Summary</h1>
        </div>

        <div className={styles.sectionBody}>
          <div className={styles.content}>
            <div className={styles.subSection}>
              <h2 className={styles.subTitle}>Who We Are</h2>
              {renderField('companyName', editableData.companyName)}
            </div>
            <div className={styles.subSection}>
              <h2 className={styles.subTitle}>What We Do</h2>
              {renderField('description', editableData.description)}
            </div>
          </div>

          <div className={styles.divider} />

          <div className={styles.attributes}>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Company URL</h2>
              {renderField('url', editableData.url)}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Industry</h2> 
              {renderField('industry', editableData.industry)}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Goods & Services</h2> 
              {renderField('goodsAndServices', editableData.goodsAndServices)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h1 className={styles.summaryTitle}>Brand Voice</h1>
        </div>

        <div className={styles.sectionBody}>
          <div className={styles.content}>
            <div className={styles.subSection}>
              <h2 className={styles.subTitle}>Our Identity</h2>
              {renderField('brandVoice', editableData.brandVoice)}
            </div>
            <div className={styles.subSection}>
              <h2 className={styles.subTitle}>Our Messaging</h2>
              {renderField('brandIdentity', editableData.brandIdentity)}
            </div>
          </div>

          <div className={styles.divider} />

          <div className={styles.attributes}>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Tone</h2>
              {renderField('tone', editableData.tone)}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Personality</h2> 
              {renderField('personality', editableData.personality)}
            </div>
            <div className={styles.attribute}>
              <h2 className={styles.subTitle}>Style</h2> 
              {renderField('style', editableData.style)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBrand;

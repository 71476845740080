// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E4CvAhbd60psTd8HnXPG{display:flex;width:100%;height:100vh;padding-top:max(150px,14.4vh);transition:all .3s ease}.E4CvAhbd60psTd8HnXPG .S0K8teF2gpMJiIHgE8af{width:55%;overflow-y:auto;padding:60px;animation:AgLrTga4vFrMvERVtk0k 2s ease-in forwards}.E4CvAhbd60psTd8HnXPG .bcWpSmvwCthrmbv3LU2k{width:45%;padding:30px 0px 30px 30px;margin-top:10px;display:flex;flex-direction:column;overflow-y:auto;animation:AgLrTga4vFrMvERVtk0k 2s ease-in forwards}.E4CvAhbd60psTd8HnXPG.dDxtHpcfFHo7bacbWmXw{padding-top:max(70px,7.2vh);transition:all .3s ease}@keyframes AgLrTga4vFrMvERVtk0k{from{opacity:0}to{opacity:1}}`, "",{"version":3,"sources":["webpack://./app/javascript/components/styles/TopicReport.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,YAAA,CACA,6BAAA,CACA,uBAAA,CAGA,4CACE,SAAA,CACA,eAAA,CACA,YAAA,CAEA,kDAAA,CAGF,4CACE,SAAA,CACA,0BAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,kDAAA,CAGF,2CACE,2BAAA,CACA,uBAAA,CAKJ,gCACE,KACE,SAAA,CAEF,GACE,SAAA,CAAA","sourcesContent":[".topicReport {\n  display: flex;\n  width: 100%;\n  height: 100vh; // This sets the height of the component to take the full viewport height\n  padding-top: max(150px, 14.4vh);\n  transition: all 0.3s ease;\n  // animation: fadeIn 1.5s ease-in forwards;\n\n  .topicData {\n    width: 55%;\n    overflow-y: auto;\n    padding: 60px;\n    // border-right: 1px solid #ccc;\n    animation: fadeIn 2s ease-in forwards;\n  }\n\n  .strategySection {\n    width: 45%;\n    padding: 30px 0px 30px 30px;\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n    animation: fadeIn 2s ease-in forwards;\n  }\n\n  &.topicFeedCollapsed {\n    padding-top: max(70px, 7.2vh);\n    transition: all 0.3s ease;\n  }\n}\n\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topicReport": `E4CvAhbd60psTd8HnXPG`,
	"topicData": `S0K8teF2gpMJiIHgE8af`,
	"fadeIn": `AgLrTga4vFrMvERVtk0k`,
	"strategySection": `bcWpSmvwCthrmbv3LU2k`,
	"topicFeedCollapsed": `dDxtHpcfFHo7bacbWmXw`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CompanyURLStep from './onboarding/CompanyURLStep';
import SocialLoginStep from './onboarding/SocialLoginStep';
import WaitingScreen from './onboarding/WaitingScreen';
import SiteInsights from './onboarding/SiteInsights';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Onboarding.module.scss';
import { useAuth } from '../contexts/AuthContext';
import { useCompanyDetails } from '../contexts/CompanyDetailsContext'; 

const OnboardingFlow = () => {
    const { currentUser, setCurrentUser } = useAuth();
    const [step, setStep] = useState(currentUser.current_step);
    const { companyDetails, setCompanyDetails } = useCompanyDetails();
    const [fadeIn, setFadeIn] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      if(step==5){
        navigate('/today');
      }
    }, [step]);


    useEffect(() => {
        // Initialize the first step with a fade-in effect
        setFadeIn(true);
    }, []);

    const nextStep = (i) => {
        updateUserStep(i)
        setFadeIn(false); // Start fade out
        setTimeout(() => {
            setStep(i);
            setFadeIn(true); // Start fade in for the next step
        }, 300); // This duration should match the CSS transition-duration
    };

    const handleCompanyUrl = url => {
        setCompanyDetails({ url });
        scrapeWebsite(url);
        const nextStepValue = window.location.hostname.includes('staging') ? 3 : 2;
        nextStep(nextStepValue);
    };

    const scrapeWebsite = async (url) => {
        try {
            const response = await axios.post('/analyze_website', { url });
            setCompanyDetails({ url, ...response.data});

            if (response.data) {
              nextStep(prevStep => (prevStep === 3 ? 4 : prevStep));
            }
    
        } catch (error) {
            console.error('Error scraping website:', error);
            // Handle errors, e.g., by showing an error message (not blocking)
        }
    };

    const handleSocialLinks = links => {
        // If site feedback is done, show waiting screen for 4 seconds
        if (companyDetails.companyName) {
            setTimeout(() => nextStep(4), 2000);
        }
        nextStep(3);
    };

    const handleComplete = async () => {
      try {
        await axios.patch('/company_details', companyDetails, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.error('Error submitting company details:', error);
        // TODO: handle errors
      }
      updateUserStep(5)
      navigate(`/today?onboarding=true`);
    }

    let stepComponent;
    switch (step) {
      case 1:
        stepComponent = <CompanyURLStep onSubmit={handleCompanyUrl} />;
        break;
      case 2:
        stepComponent = <SocialLoginStep onSubmit={handleSocialLinks} onSkip={handleSocialLinks} />;
        break;
      case 3:
        stepComponent = <WaitingScreen text="Taking a look at your brand" nextStep={nextStep}/>;
        break;
      case 4:
        stepComponent = <SiteInsights companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} onNext={handleComplete} />;
        break;
      default:
        stepComponent = null;
        break;
    }

    const updateUserStep = async (step) => {
      try {
        const response = await axios.put(`/users/${currentUser.id}/update_current_step`, { currentStep: step });
        if(response){
          setCurrentUser(response);
        }
      } catch (error) {
        console.error('Error updating  users:', error);
      }
    }

    return (
      <div className={styles.onboardingContainer}>
        <div className={`${styles.stepContent} ${fadeIn ? styles.fadeIn : styles.fadeOut}`}>
          {stepComponent}
        </div>
      </div>
    );
};

export default OnboardingFlow;
import React, { useState, useEffect, useRef } from 'react';
import styles from './styles/TopicFeed.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faEllipsisH, faNewspaper, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faLinkedin, faRedditAlien, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useFeed } from '../contexts/FeedContext';
import { useCompanyDetails } from '../contexts/CompanyDetailsContext'; 

const TopicFeed = ({ children, collapsed, setCollapsed }) => {
  const { topic, setTopic, feed } = useFeed();
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const { current } = scrollContainerRef;
    if (current) {
      current.addEventListener('scroll', checkScrollPosition);
      checkScrollPosition(); // Initial check on mount
    }
    return () => {
      if (current) {
        current.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, [feed]);

  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 50 && !collapsed) {  // Adjust the 50px as needed
        setCollapsed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const checkScrollPosition = () => {
    const { current } = scrollContainerRef;
    if (!current) {
      return;
    }
    // Show the indicator if there is space to scroll to the right
    const canScrollRight = current.scrollWidth > current.clientWidth + current.scrollLeft;
    setShowScrollIndicator(canScrollRight);
  };

  return (
    <div className={styles.topicFeed}>
      <TodayBox collapsed={collapsed} onToggleCollapse={() => setCollapsed(!collapsed)}>
        {children}
      </TodayBox>
      <div className={styles.scrollWrapper} ref={scrollContainerRef}>
        <div className={styles.scrollContainer}>
          {feed?.map((item, index) => (
            <TopicItem
              key={index}
              order={index + 1}
              data={item}
              collapsed={collapsed}
              isSelected={item === topic}
              onSelect={() => setTopic(item)}
            />
          ))}
        </div>
      </div>
      {
        showScrollIndicator && (
          <div className={styles.scrollIndicator}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )
      }
    </div>
  );
};

const TopicItem = ({ order, data, collapsed, isSelected, onSelect }) => {
  const itemClass = isSelected ? `${styles.topicItem} ${styles.selected}` : styles.topicItem;
  const formattedRanking = getOrdinalSuffix(order);

  const socialMedia = data.recommendations.map(rec => rec.domain);

  return (
    <div className={collapsed ? `${itemClass} ${styles.collapsedItem}` : itemClass} onClick={onSelect}>
      <div className={`${styles.label} ${styles[getLabelClass(data.urgency)]}`}>{data.urgency}</div>
      <div className={styles.headline}>{data.name}</div>
      {!collapsed && (
        <>
          <div className={styles.subHeadline}>{data.description.slice(0,130)}...</div>
          <div className={styles.rank}>{formatRankWithSuffix(formattedRanking)}</div>
          <div className={styles.socialIcons}>
            {socialMedia.includes("twitter") && <FontAwesomeIcon icon={faXTwitter} />}
            {socialMedia.includes("facebook") && <FontAwesomeIcon icon={faFacebook} />}
            {socialMedia.includes("linkedin") && <FontAwesomeIcon icon={faLinkedin} />}
            {socialMedia.includes("reddit") && <FontAwesomeIcon icon={faRedditAlien} />}
            {socialMedia.includes("pressRelease") && <FontAwesomeIcon icon={faTopicpaper} />}
          </div>
        </>
      )}
    </div>
  );
};


const TodayBox = ({ children, collapsed, onToggleCollapse }) => {

  const { date, setDate } = useFeed();
  const { companyDetails } = useCompanyDetails();

  //currently we are using the webstite details created at as the minimum date but we need to change it to the subscription date in future
  const minDate = new Date(companyDetails.createdAt);

  // Formatter for the date display
  const formatDate = d => {
    return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const datePicker = (<DatePicker
            selected={date}
            onChange={val => setDate(val)}
            customInput={<button style={{ border: 'none', background: 'transparent' }}><FontAwesomeIcon icon={faCaretDown} /></button>}
            maxDate={new Date()}
            minDate={minDate}
            popperPlacement="bottom"
            popperProps={{
              positionFixed: true,
              strategy: "fixed" 
            }}
          />)

  return (
    <div className={collapsed ? `${styles.staticBox} ${styles.collapsedStatic}` : styles.staticBox }>
      <div className={styles.today}>
        {describeDate(date)}
        <button onClick={(e) => { e.stopPropagation(); onToggleCollapse(); }} className={styles.collapseButton}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </div>
      {!collapsed && 
        <>
          <div className={styles.date}>
            <div>{formatDate(date)}</div>
            {datePicker}
          </div>
          {children}
        </>
      }
    </div>
  );
};

const getOrdinalSuffix = (number) => {
  const j = number % 10,
        k = number % 100;
  if (j === 1 && k !== 11) {
    return number + "ST";
  }
  if (j === 2 && k !== 12) {
    return number + "ND";
  }
  if (j === 3 && k !== 13) {
    return number + "RD";
  }
  return number + "TH";
}

const formatRankWithSuffix = (rank) => {
  const base = rank.slice(0, -2);
  const suffix = rank.slice(-2);
  return (
    <>
      {base}<span className={styles.suffix}>{suffix}</span>
    </>
  );
};

const getLabelClass = (label) => {
  switch(label) {
    case("Breaking"):
      return "red";
    case("This Week"):
      return "green";
    case("This Month"):
      return "blue";
  }
}

const describeDate = (inputDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's time to midnight for accurate comparison

  const date = new Date(inputDate);
  date.setHours(0, 0, 0, 0); // Ensure time components do not affect the comparison

  // Calculate the difference in days
  const timeDiff = today.getTime() - date.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  // Return the appropriate phrase based on the difference in days
  if (daysDiff < 1) {
    return 'Today';
  } else if (daysDiff < 2) {
    return 'Yesterday';
  } else if (daysDiff <= 7) {
    return `${Math.floor(daysDiff)} days ago`;
  } else if (daysDiff <= 30) {
    const weeks = Math.floor(daysDiff / 7);
    return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
  } else if (daysDiff <= 365) {
    const months = Math.floor(daysDiff / 30);
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else {
    const years = Math.floor(daysDiff / 365);
    return years === 1 ? '1 year ago' : `${years} years ago`;
  }
}



export default TopicFeed;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles/Settings.module.scss';
import Button from './shared/Button';

const Settings = () => {
    const [email, setEmail] = useState('');
    const [invitations, setInvitations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchInvitations = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('/users/invitations');
            setInvitations(data);
        } catch (err) {
            setError('Failed to load invitations');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInvitations();
    }, []);

    const handleInvite = async () => {
        if (!email) {
            setError('Please provide an email address.');
            return;
        }
        setLoading(true);
        try {
            await axios.post('/users/invite', { emails: [email] });
            fetchInvitations();
            setEmail('');
            setError('');
        } catch (err) {
            setError(err.response.data.error || 'Failed to send invitation.');
        } finally {
            setLoading(false);
        }
    };

    const handleResendInvite = async (email) => {
      setLoading(true);
      try {
        await axios.post('/users/resend_invite', { email });
        // alert(`Resent invitation to ${email}`);
        fetchInvitations();  // Refresh the list of invitations
      } catch (error) {
        alert('Failed to resend invitation. ' + error.response.data.error);
      } finally {
        setLoading(false);
      }
    };

    return (
    <div className={styles.settings}>
        <h1>Invite Teammates</h1>
        <div className={styles.inviteSection}>
            <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter email to invite"
                className={styles.inputField}
            />
            <Button onClick={handleInvite} loading={loading} text={loading ? 'Inviting...' : 'Invite'} />
            {error && <p className={styles.error}>{error}</p>}
        </div>
        <div className={styles.invitationsList}>
            <h2>Invitations Sent</h2>
            {invitations.length > 0 ? (
                <ul>
                    {invitations.map((invite, index) => (
                        <li key={index}>
                            <div>{invite.email} - <b>{invite.invitation_status}</b></div>
                            {invite.invitation_status === 'pending' && (
                                <Button onClick={() => handleResendInvite(invite.email)} text="Resend" />
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No invitations sent yet.</p>
            )}
        </div>
    </div>
);
};

export default Settings;

import React from 'react';
import styles from '../styles/topic_report/HeadlineSection.module.scss';

const Headline = ({ topicHeadline, subHeadline }) => {
  return (
    <div className={styles.headlineComponent}>
      <div className={styles.topicHeadline}>
        <i>What's happening in <b>{topicHeadline}</b></i>
      </div>
      <div className={styles.subHeadline}>
        {subHeadline}
      </div>
    </div>
  );
};

export default Headline;

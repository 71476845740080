import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/shared/Spinner";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user'));
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axios.get("/fetch_current_user");
        setCurrentUser(response.data);
      } catch (error) {
        console.error("Failed to fetch current user: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };

    fetchCurrentUser();
  }, []);

  const navigate = useNavigate();

  // Simulate user login
  const login = (response) => {
    setCurrentUser(response.data);
    localStorage.setItem('user', JSON.stringify(response.data));
    axios.defaults.headers.common['Authorization'] = response.headers.authorization;
    navigate("/brand-center");
  };

  // Simulate user logout
  const logout = () => {
    axios.delete("/users/sign_out");
    setCurrentUser(null);
    localStorage.removeItem('user');
    axios.defaults.headers.common['Authorization'] = null;
  };

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  });

  const value = {
    currentUser,
    login,
    logout,
    setCurrentUser
  };

  if (loading) {
    return <Spinner  width="50" height="50" marginTop="15%"/>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
